<template>
  <div class="zjliyuan-admin">
    <div class="zjliyuan-admin-top">
      <div class="zjliyuan-admin-logo">
        <img src="../../assets/img/admin_logo.png" height="38" />
        立元科技
      </div>
      <div class="zjliyuan-admin-tuic" @click="logOut">退出</div>
    </div>
    <div class="zjliyuan-admin-main">
      <div class="zjliyuan-admin-left">
        <lyt-menu-third-vertical-left
          :menuList="menuAll"
          :selectedMenuId="selectedMenuId"
          :showIcon="false"
          @select="select"
        ></lyt-menu-third-vertical-left>
      </div>
      <div class="zjliyuan-admin-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import defaults from "../../defaults/defaults";
export default {
  name: "Admin",
  provide() {
    return {
      updateMenu: this.updateMenuList,
      getCurrentMenu: () => this.selectedMenu,
    };
  },
  data() {
    return {
      selectedMenu: {},
      selectedMenuId: "",
      originalMenu: [],
      menuAll: [],
      menuList: [
        {
          MenuName: "通用配置",
          MenuId: "config",
          path: "/menuConfig",
          MenuItemList: [
            {
              MenuName: "菜单配置",
              MenuId: "menuConfig",
              MenuItemList: [],
              ShowIcon: "false",
              path: "/menuConfig",
            },
            {
              MenuName: "其他配置",
              MenuId: "otherConfig",
              MenuItemList: [],
              ShowIcon: "false",
              path: "/otherConfig",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    /**
     *获取菜单
     */
    getMenuList() {
      let params = {
        QueryParam: {},
      };
      api.getMenuList(params).then((res) => {
        this.originalMenu = res.data.Results || {};
        let menus = [];
        this.originalMenu.forEach((item) => {
          let url = item.Attributes.Url;
          let path = url ? url : defaults.menuMap[item.Id];
          let children = item.Children || [];
          children.forEach((child) => {
            child.MenuId = child.Id;
            child.MenuName = child.Label;
            child.path = child.Attributes.Url;
          });
          let menuItem = Object.assign(
            {
              MenuId: item.Id,
              path: path,
              MenuName: item.Label,
              MenuItemList: children,
              type: 0,
            },
            item
          );
          menus.push(menuItem);
        });
        this.menuAll = menus.concat(this.menuList);
      });
    },
    /**
     * 更新菜单
     * @param {*} type
     */
    updateMenuList(type) {
      console.log("typee", type);
    },
    select(item) {
      this.getAuth();
      this.selectedMenu = item;
      let path = item.path;
      if (item.type == 0) {
        this.$router.push({ path: path });
      } else {
        let id = item.Id;
        let aboutId = defaults.menuIds.about;
        if (id && id.indexOf(aboutId) > -1) {
          this.$router.push({ path: path });
          return;
        }
        this.$router.push({ path: path, query: { id: item.MenuId } });
      }
    },
    getAuth() {
      api.getAuth().then();
    },
    /**
     * 退出
     */
    logOut() {
      this._dialog.confirm("确认退出系统吗？", () => {
        this.logOutSys();
      });
    },
    logOutSys() {
      localStorage.removeItem("myStorage");
      top.window.location.href =
        "http://" + window.location.host + "/app-website/lyte/login.html";
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/public.css";
@import "../../assets/css/cover.scss";
@import "../../assets/css/dialog.scss";
@import "../../assets/css/icon.scss";
@import "../../assets/css/web.scss";
html,
body {
  width: 100%;
  height: 100%;
  background: #eceef1;
  overflow-x: hidden;
}
.zjliyuan-admin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .zjliyuan-admin-top {
    height: 50px;
    background: #2d4f99;

    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    .zjliyuan-admin-tuic {
      color: #fff;
      font-size: 18px;
      align-items: center;
      line-height: 50px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .zjliyuan-admin-logo {
      height: 50px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 18px;
      text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.5);
      img {
        margin-right: 10px;
      }
    }
  }
  .zjliyuan-admin-main {
    flex: 1;
    min-height: 500px;
    display: flex;
    .zjliyuan-admin-left {
      box-sizing: border-box;
      padding-top: 6px;
      width: 200px;
      background: #e5e6e7;
      .lyt-third-level-left-menu {
        .el-submenu__title {
          width: 180px;
        }
        .el-menu {
          width: 200px;
          .el-menu-item {
            width: 180px;
          }
        }
      }
    }
    .zjliyuan-admin-right {
      flex: 1;
      height: 100%;
      min-width: 600px;
      padding: 0 0 0 20px;
      box-sizing: border-box;
    }
  }
}
</style>
